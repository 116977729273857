<template>
  <section class="input-form">
    <div class="input-form__title">
      <img
        src="../assets/Currys--Logo.png"
        srcset="../assets/Currys--Logo.png 1x, ../assets/Currys--Logo@2x.png 2x"
        alt="Currys Logo"
      >
      <h3>Your Plan</h3>
    </div>
    <h1>Fill in your details to have a chance to win!</h1>
    <form
      class="js-cm-form"
      id="subForm"
      action="https://www.createsend.com/t/subscribeerror?description="
      method="post"
      ref="form"
      data-id="2BE4EF332AA2E32596E38B640E90561929B5D4754D79579D7FC253558816EE7BFC7FC93EE12C33D1D104D39A61A2E4AF949943DB4006F522C49BC207D64C0E3F"
    >
        <!-- Full Name -->
        <div>
          <label for="fieldName">Full Name</label>
          <input
            v-model="name"
            :class="{'sc-iwsKbI iMsgpL': true, 'error': invalidName}"
            aria-label="Name"
            id="fieldName"
            maxlength="200"
            name="cm-name"
            required
          />
        </div>

        <!-- Email -->
        <div>
          <label for="fieldEmail">Your Plan Email</label>
          <input
            v-model="email"
            autocomplete="email"
            aria-label="Your Plan Email"
            :class="{'js-cm-email-input qa-input-email sc-iwsKbI iMsgpL': true, 'error': invalidEmail}"
            id="fieldEmail"
            maxlength="200"
            name="cm-jttlcd-jttlcd"
            required
            type="email"
          />
        </div>
        <!-- Your Plan ID Number -->
        <div v-if="ypidDisabled && ypid">
          <label for="fieldtrjriru">Your Plan ID Number: <strong>{{ypid}}</strong><span @click="resetPlanID" class="text-link-btn">Change</span></label>
          <input
            class="sc-iwsKbI iMsgpL"
            v-model="ypid"
            :readonly="ypidDisabled"
            type="hidden"
            aria-label="Your Plan ID Number"
            id="fieldtrjriru"
            maxlength="200"
            name="cm-f-trjriru"
            title="10 digit number beginning with '10'"
          />
        </div>
        <div v-if="!ypidDisabled">
          <label for="fieldtrjriyl">Your Plan ID Number</label>
          <input
            required
            v-model="ypid"
            type="text"
            aria-label="Your Plan ID Number"
            id="fieldtrjriru"
            maxlength="200"
            :class="{'sc-iwsKbI iMsgpL': true, 'error': invalidPlanID}"
            name="cm-f-trjriru"
            title="Invalid plain id number"
          />
          <small v-if="invalidPlanID" style="margin-top: 5px;color: red;">Invalid Your Plain ID Numer</small>
        </div>
        <!-- Checkbox -->
        <div>
          <div>
            <!-- START: Updated custom checkboxes -->
            <div class="custom-radio-stack">
              <div class="custom-radio">
                <input type="radio" id="sayYes" class="custom-radio-input" name="cm-privacy-consent" @change="updateMarketingConsent" required />
                <label for="sayYes" class="custom-radio-label"><span>YES, I would like to receive special offers and promotions by email from Creation.</span></label>
              </div>
              <div class="custom-radio">
                <input type="radio" id="sayNo" class="custom-radio-input" name="cm-privacy-consent" />
                <label for="sayNo" class="custom-radio-label">No, I’m happy missing out on great offers.</label>
              </div>
              <input id="cm-privacy-consent-hidden" name="cm-privacy-consent-hidden" type="hidden" value="true">
              <input
                v-model="marketingConsent"
                id="fieldtrjriyl"
                name="cm-fo-trjriyl"
                type="hidden"
              >
            </div>
            <!-- END: Updated custom checkboxes-->
          </div>
          <p class="terms-conditions"><strong @click="openModal" rel="noopener">Terms and Conditions</strong> apply. Prize draw excludes Northern Ireland customers.</p>
        </div>
      <button
        type="submit"
        @click="submitForm"
      >Enter now ></button>
    </form>
    <div class="input-form__bottom">
      <p>We are sure you won’t want to but if you do, you can opt out of marketing at any time. Change of marketing preferences may take up to 7 days to take effect.</p>
      <p>To access our Data Protection Notice, go to <a href="https://www.creation.co.uk/about-us/data-protection-notice" target="_blank">www.creation.co.uk/about-us/data-protection-notice</a></p>
    </div>
  </section>
</template>

<script>
const regex = /^[0-9]{10}$/
export default {
  name: 'InputForm',
  data () {
    return {
      marketingConsent: '3281309',
      ypid: null,
      name: '',
      email: '',
      ypidDisabled: false,
      invalidPlanID: false,
      invalidEmail: false,
      invalidName: false
    }
  },
  watch: {
    ypid (val) {
      if (regex.test(val)) {
        this.invalidPlanID = false
      } else {
        this.invalidPlanID = true
      }
    },
    email (val) {
      // regex validate email
      if (this.validateEmail(val)) {
        this.invalidEmail = false
      } else {
        this.invalidEmail = true
      }
    }
  },
  methods: {
    validateEmail (email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    openModal () {
      this.$parent.modalActive = true
    },
    getYpid () {
      const urlParams = new URLSearchParams(window.location.search)
      const ypid = urlParams.get('ypid')
      if (!ypid) return
      // regex validate only numeric and 9 digits
      if (regex.test(ypid)) {
        this.ypid = ypid
        this.ypidDisabled = true
      }
    },
    resetPlanID () {
      this.ypidDisabled = false
      this.ypid = ''
    },
    submitForm (e) {
      this.invalidPlanID = false
      this.invalidEmail = false
      this.invalidName = false
      const isValidPlanID = regex.test(this.ypid)
      if (!this.ypid || !this.email || !this.name || !isValidPlanID) {
        e.preventDefault()
      }
      if (!this.ypid || !isValidPlanID) {
        this.invalidPlanID = true
      }
      if (!this.email) {
        this.invalidEmail = true
      }
      if (!this.name) {
        this.invalidName = true
      }
      console.log('submitForm')
      /* e.target.innerText = 'Please wait...'
      e.target.classList.add('disabled')
      setTimeout(() => {
        e.target.innerText = 'Enter now >'
        e.target.classList.remove('disabled')
      }, 3000) */
    },
    updateMarketingConsent (e) {
      this.marketingConsent = e.target.checked ? '3346280' : '3346281'
    }
  },
  mounted () {
    this.getYpid()
  }
}
</script>

<style scoped lang="scss">
.terms-conditions strong{
  cursor: pointer;
}
  .input-form {
    background: #fff;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.16);
    border-radius: 15px;
    &__title {
      display: flex;
      justify-content: space-between;
      padding: 20px 40px;
      align-items: center;
      h3 {
        font-size: 1.5rem;
      }
      img {
        width: 70px;
      }
      @media only screen and (max-width: 768px) {
        padding: 30px 20px;
      }
    }
    &__bottom {
      padding: 0 40px 30px;
      font-size: 0.875rem;

      a {
        color: inherit;
      }

      @media only screen and (max-width: 768px) {
        padding: 0 20px 20px;
      }
    }
    h1 {
      color: #fff;
      background-color: #4c12a1;
      padding: 20px 72px;

      @media only screen and (max-width: 768px) {
        padding: 20px;
      }
    }
    form {
      display: flex;
      flex-wrap: wrap;
      text-align: left;
      padding: 20px 40px;

      @media only screen and (max-width: 768px) {
        padding: 20px;
      }

      > div label {
        color: #56707A;
      }

      > div, .privacy-consent {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 1.25rem;
      }
      input {
        width: 100%;
        height: 3rem;
        font-size: 1.25rem;
        padding: 8px 15px;
        border-radius: 8px;
        margin-top: 6px;
        border: 1px solid #56707A;
        &.error{
          border: 1px solid red;
        }
        &[disabled] {
          color: rgba(86,112,122,0.75);
          background: rgba(86,112,122,0.25);
          border-color: rgba(86,112,122,0.5);
        }
      }
      .privacy-consent {
        margin-top: 0.5rem;
        align-items: center;
        justify-content: space-between;

        label {
          width: 90%;
        }
        input {
          width: unset;
        }
      }
      .privacy-consent, .terms-conditions {

        strong {
          font-weight: normal;
          text-decoration: underline;
        }
      }
    }
  .text-link-btn{
    color: #4c12a1;
    cursor: pointer;
    font-weight: bold;
    margin-left: .5rem;
  }
    button {
      width: 100%;
      border: solid 3px #4c12a1;
      border-radius: 30px;
      background-color: unset;
      font-family: 'Currys Sans', sans-serif;
      font-size: 1.75rem;
      font-weight: 700;
      margin-top: -.5rem;
      padding: 0.625rem 1.5rem;
      color: #4c12a1;
      transition: 300ms ease-in-out all;
      &:hover {
        background-color: #4c12a1;
        color: #fff;
        cursor: pointer;
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }
    }

    .custom-radio-stack {
      margin-bottom: 24px;
    }
    .custom-radio {
      display: block;
      width: 100%;
      position: relative;
      .custom-radio-input {
        opacity: 0;
        position: absolute;
      }
      .custom-radio-label {
        display: block;
        padding: 10px 0 10px 48px;
        cursor: pointer;
        &:hover {
          cursor: pointer;
        }
        span {
          font-weight: 700;
          color: #4c12a1;
        }
        &::before {
          display: block;
          border: 1px solid #56707A;
          width: 30px;
          height: 30px;
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          margin-top: -15px;
          border-radius: 8px;
          cursor: pointer;
        }
        &::after {
          display: block;
          position: absolute;
          content: '';
          left: 8px;
          width: 12px;
          height: 6px;
          top: 50%;
          margin-top: -6px;
          border-bottom: 2px solid #fff;
          border-left: 2px solid #fff;
          transform: rotate(-45deg);
        }
      }
      .custom-radio-input:checked + .custom-radio-label {
        &::before {
          background: #4c12a1;
          border: rgba(0,0,0,0.5);
        }
      }
    }
    p {
      text-align: left;
      margin-bottom: 1rem;
    }
    .privacy-consent {
      position: relative;

      input {
        margin: 1px 0 0;
        cursor: pointer;
        opacity: 0;
        filter: alpha(opacity=0);
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        background: red;
        width: 48px;
        height: 27px;
        &:checked + label {

          &:before {
            background-color: #4c12a1;
            content: 'YES';
            padding-left: 6px;
          }

          &:after {
            left: 24px;
          }
        }
      }

      label {
        position: relative;
        padding-left: 4rem;

        &:before, &:after {
          position: absolute;
          border-radius: 16px;
          transition: background-color 0.3s, left 0.3s;
        }

        &:before {
          content: 'NO';
          color: #fff;
          box-sizing: border-box;
          padding-left: 29px;
          font-size: 0.625rem;
          line-height: 27px;
          background-color: grey;
          left: 0;
          top: 0;
          height: 27px;
          width: 48px;
          border-radius: 16px;
        }

        &:after {
          content: "";
          letter-spacing: 20px;
          background: #fff;
          left: 3px;
          top: 3px;
          height: 21px;
          width: 21px;
        }
      }
    }
  }
</style>
