<template>
  <section class="info-block">
    <h1>{{ title }}</h1>
    <p class="description" v-html="description"></p>
  </section>
</template>

<script>
export default {
  name: 'infoBlock',
  props: {
    title: String,
    description: String
  }
}
</script>

<style lang="scss">
  .info-block {
    margin-bottom: 4rem;
    max-width: 680px;

    h1 {
      font-size: 42px;
      line-height: 1.2em;
      margin-bottom: 1rem;
    }

    p {
      font-size: 30px;
      line-height: 1.2em;
      color: #4c12a1;
    }

    strong {
      color: #e4016c;
    }
  }
</style>
