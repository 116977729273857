<template>
  <img
    src="../assets/Currys--Gift-Card-Design@2x.png"
    srcset="../assets/Currys--Gift-Card-Design.png 1x, ../assets/Currys--Gift-Card-Design@2x.png 2x"
    class="image-card"
    alt="topImage">
</template>

<script>
export default {
  name: 'imageCard',
  props: {
    imgPath: String,
    title: String,
    logoPath: String
  }
}
</script>

<style scoped lang="scss">
.image-card {
  position: relative;
  width: 100%;
}
</style>
