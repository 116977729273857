import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: true,
    meta: {
      title: 'Your Plan Prize Draw'
    }
  },
  {
    path: '/:page',
    name: 'Success',
    component: Home,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = to.meta ? to.meta.title : 'Your Plan Prize Draw'
  next()
})

export default router
