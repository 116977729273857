<template>
  <router-view/>
</template>

<style lang="scss">

@font-face {
  font-family: "Currys Sans";
  font-weight: 400;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("CurrysSans"), url("./fonts/CurrysSans/CurrysSans-Regular.woff2") format("woff2"), url("./fonts/CurrysSans/CurrysSans-Regular.woff") format("woff");
}

@font-face {
  font-family: "Currys Sans";
  font-weight: 700;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local("CurrysSans Head"), url("./fonts/CurrysSans/CurrysSans-Headline.woff2") format("woff2"), url("./fonts/CurrysSans/CurrysSans-Headline.woff") format("woff");
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  min-height: 100vh;
  background: url('assets/background.jpg') no-repeat 50% 50% #ffebb7;
  background-size: 1540px 800px;
  display: flex;
  vertical-align: middle;
  align-content: center;
  padding: 70px 20px;

  @media only screen and (max-width: 768px) {
    padding: 64px 32px;
  }
}

.container {
  max-width: 1320px;
  margin-left: auto;
  margin-right: auto;
}

#app {
  font-family: "Currys Sans", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

h1 {
  font-weight: 700;
  font-size: 1.8em;
}

h1, h3 {
  color: #4c12a1;
}

p {
  color: #56707A;
}

</style>
