<template>
  <main>
    <div class="container">
      <div class="row">
        <div class="column column--left">
          <InfoBlock
            title="Win 1 of 3 £100 Currys gift cards!"
            description="<strong>We love tech. Who doesn't?</strong> It’s why we’re giving away a £100 Currys gift card to three lucky winners."
          />
          <ImageCard
            imgPath=""
            title="Best. Gift. Ever."
            logoPath=""
          />
          <p class="powered-by">Your Plan is powered by&nbsp;<img src="../assets/creation-logo.png" alt="" width="97" height="25"></p>
        </div>
        <div class="column column--right">
          <InputForm />
        </div>
      </div>
    </div>
  </main>
  <section id="modal" :class="{ active: modalActive }">
    <div class="modal">
      <div class="modal__container">
        <button id="close-modal" @click="closeModal">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#fff">
            <path d="m19 6-1-1-6 6-6-6-1 1 6 6-6 6 1 1 6-6 6 6 1-1-6-6z"/>
          </svg>
        </button>
        <div
          v-if="success"
          class="modal__container--text"
        >
          <h1>Thank you for entering!</h1>
          <br>
          <strong>The winner will be notified via email by 9th September 2022</strong>
          <br>
          <p>Please note if you’ve changed your marketing preferences, this may take up to 7 days to take effect. If you change your mind you can update your marketing preferences after this time in the settings section of your Online Account Manager.</p>
        </div>
        <div
          v-else
          class="modal__container--text"
          style="text-align: left"
        >
          <h2>Terms and Conditions</h2>
          <br/>
          <h3>Eligibility:</h3>
          <br/>
          <p>This is a free prize draw to win one of three Currys £100 Gift Cards and is open to existing selected Your Plan customers of Creation Consumer Finance Limited only. The only method of entering this prize draw is by submitting details directly from received communication/banners or landing page.</p>
          <p>Only one entry per household and recipient. The prize draws are only open to UK residents aged 18 or over, excluding Northern Ireland. By entering this promotion, you confirm that you have read and agree to these terms and conditions. General terms and conditions of a Currys Gift Card can be found at <a href="https://www.currys.co.uk/services/gift-cards-page.html" target="_blank">https://www.currys.co.uk/services/gift-cards-page.html</a></p>
          <p>The email address and Your Plan ID used to enter must both correspond with the ones to the selected account that you have registered with us.</p>
          <p>Employees of Creation Consumer Finance Limited (or their immediate families), its group companies, agents or anyone else professionally connected to these prize draws are excluded. Employees from Currys Group Ltd, Creation UK group companies, current business partners, and their employees and any BNP Paribas Group companies are not eligible to enter this prize draw.</p>
          <br/>
          <h3>Prize and Fulfilment:</h3>
          <br/>
          <p>The retailer’s terms and conditions for use of the £100 Gift Card will apply. Photocopies will not be accepted. The prize is non-transferable, and no cash equivalent will be given, we reserve the right to amend, withdraw or cancel any aspect of our prize draws without prior notice if factors beyond our control require us to do so. The Promoter may award a substitute prize of equal value if for any reason our stated Currys Gift Card prize is not issued.</p>
          <p>Only completed and eligible full details provided will be entered. Changes to marketing preference will only be updated on completed and eligible accounts where full details have been provided. Information of the winners name, organisation and photograph may be used in Creation UK internal and external publicity.</p>
          <p>Closing date 24 November 2022. The random prize draw will take place on or before 9 December 2022, and the correct completed entry will be drawn. The winner will be notified by 16 December 2022, using the contact details/email address provided. Each winner will be randomly selected and will receive a £100 Currys Gift Card sent by post. If contact with a winner cannot be made within two working days a further prize draw will be drawn again in respect of any prize not claimed after the first prize draw and awarded to the person(s) drawn first at that time.</p>
          <p>The promoters decision is final.</p>
          <p>Details of the winner may be obtained after 2 January 2023 by sending a self-addressed reply paid envelope to Currys £100 Gift Card Prize Draw Results, Public Relations Department, Creation Consumer Finance Limited at the address shown below.</p>
          <br/>
          <h3>Promoter:</h3>
          <br/>
          <p>Creation Consumer Finance Limited is authorised and regulated by the Financial Conduct Authority. Creation Consumer Finance Limited. Registered Office: 4th - 6th floor, Wellington Buildings, 2-4 Wellington Street, BT1 6HT. Registered in Northern Ireland NI32565.Authorised and regulated by the Financial Conduct Authority.</p>
          <p>The Promoter takes data protection seriously.</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import InfoBlock from '@/components/InfoBlock.vue'
import ImageCard from '@/components/ImageCard.vue'
import InputForm from '@/components/InputForm.vue'

export default {
  name: 'Home',
  components: {
    InfoBlock,
    ImageCard,
    InputForm
  },
  props: {
    page: {
      type: String,
      required: false
    },
    ypid: {
      type: String,
      required: false,
      default: null
    }
  },
  data () {
    return {
      modalActive: false,
      success: false
    }
  },
  mounted () {
    const externalScript = document.createElement('script')
    externalScript.setAttribute('src', 'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js')
    document.head.appendChild(externalScript)
    if (this.page === 'success') {
      this.success = true
      this.modalActive = true
    }
  },
  methods: {
    closeModal () {
      this.$data.modalActive = false
      this.success = false
    },
    openModal () {
      this.$data.modalActive = true
    }
  }
}
</script>

<style scoped lang="scss">
  main, footer {
    width: 100%;
    position: relative;
    z-index: 1;
  }
  .row {
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1024px) {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  .column {
    display: flex;
    flex-direction: column;
    position: relative;

    &--left {
      flex: 0 1 720px;
      margin-right: min(5vw, 120px);
    }

    &--right {
      flex: 0 1 480px;
    }

    @media only screen and (max-width: 1024px) {
      &--left {
        margin-bottom: 50px;
        margin-right: 0;
      }
    }
  }

  .powered-by {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #000;
    margin-top: 6rem;

    @media only screen and (max-width: 1024px) {
      display: none;
    }
  }

  #modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.3);
    padding: 20px;
    opacity: 0;
    z-index: 0;

    &.active {
      opacity: 1;
      z-index: 99;
    }

    .modal {
      &__container {
        background-color: #fff;
        max-width: 660px;
        max-height: 90vh;
        position: absolute;
        top: 50%;
        left: 50%;
        padding: 48px;
        transform: translate(-50%, -50%);
        border-radius: 15px;

        &--text {
          max-height: 80vh;
          overflow-y: scroll;
          overflow-x: unset;
          width: 100%;
        }

        h1 {
          font-size: 50px;
          color: #4C12A1;
        }

        strong {
          display: block;
          font-size: 20px;
          margin-bottom: 24px;
        }

        p {
          margin-bottom: 15px;
        }

        button {
          position: absolute;
          top: -23px;
          right: -23px;
          border: solid 3px #4c12a1;
          border-radius: 30px;
          background-color: #4c12a1;
          padding: 8px;
          color: #fff;
          transition: 300ms ease-in-out all;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &:hover {
            background-color: lighten(#4c12a1, 20%);
          }
        }
      }
    }
  }
</style>
